export const releaseNotes = [
  {
    version: '1.4',
    date: '10/02/2025',
    notes: [
      {
        key: 'list-item',
        value: `Nb of assessed workers in Skills/Workers Drill to detail page`,
      },
      {
        key: 'list-item',
        value: `Skills/Data Drill to detail page to analyse Skills source data`,
      },
      {
        key: 'list-item',
        value: 'Usage monitoring accessible to End Users',
      },
      {
        key: 'list-item',
        value: `E2M standard Referent role`,
      },
      {
        key: 'list-item',
        value: 'Organization export configuration improvement',
      },
      {
        key: 'list-item',
        value: 'GBU and BL renaming capability - for Admins and Super Users',
      },
      {
        key: 'list-item',
        value: 'CC & BL moving capability - for Admins and Super Users',
      },
      {
        key: 'list-item',
        value: 'User feedback export capability - for Admins and Super Users',
      },
    ],
  },
  {
    version: '1.3',
    date: '07/10/2024',
    notes: [
      {
        key: 'list-item',
        value: `User satisfaction feedback collection`,
      },
      {
        key: 'list-item',
        value: `Skills KPI drilling improvement, i.e. number of assessed Workers, number of assessments per Worker, better mapping of Workers on the Organization, enhanced UI`,
      },
      {
        key: 'list-item',
        value: 'Mood & Confidence to deliver participation rate improvment for shared CCs',
      },
      {
        key: 'list-item',
        value: `Management of the new "Other Referent" category`,
      },
      {
        key: 'list-item',
        value: 'Management of additional Offshoring CCs',
      },
      {
        key: 'list-item',
        value: 'Engineering Organization search optimization',
      },
      {
        key: 'list-item',
        value: 'Automated production of Engineering KPIs (Offshoring) for the Operation Cockpit',
      },
      {
        key: 'list-item',
        value: 'Usage Monitoring drilling - from Admins and Super Users',
      },
    ],
  },
  {
    version: '1.2',
    date: '18/07/2024',
    notes: [
      {
        key: 'list-item',
        value: `Management of serveral Referents per category and new "Other Referent" category`,
      },
      {
        key: 'list-item',
        value: `Management of "Other" CC type and capability to filter CC types`,
      },
      {
        key: 'list-item',
        value: 'Engineering Efficiency Index (EEI) KPI',
      },
      {
        key: 'list-item',
        value: 'Skills Drill to detail UX improvments, including Skills data table',
      },
      {
        key: 'list-item',
        value: 'KPI description update',
      },
      {
        key: 'list-item',
        value: 'BI integration & navigation performance improvement',
      },
      {
        key: 'list-item',
        value: 'EMW solution usage monitoring (MAU) - for Admins and Super Users',
      },
    ],
  },
  {
    version: '1.1',
    date: '19/04/2024',
    notes: [
      {
        key: 'list-item',
        value: `Management of serveral Referents per category and new "Other Referent" category`,
      },
      {
        key: 'list-item',
        value: `Management of "Other" CC type and capability to filter CC types`,
      },
      {
        key: 'list-item',
        value: 'Engineering Efficiency Index (EEI) KPI',
      },
      {
        key: 'list-item',
        value: 'Skills Drill to detail UX improvments, including Skills data table',
      },
      {
        key: 'list-item',
        value: 'KPI description update',
      },
      {
        key: 'list-item',
        value: 'BI integration & navigation performance improvement',
      },
      {
        key: 'list-item',
        value: 'EMW solution usage monitoring (MAU) - for Admins and Super Users',
      },
    ],
  },
  {
    version: '1.1',
    date: '19/04/2024',
    notes: [
      {
        key: 'list-item',
        value: `"Double SSO" login`,
      },
      {
        key: 'list-item',
        value: 'Direct data collection from files & forms for EEI & WFB',
      },
      {
        key: 'list-item',
        value: 'Automated WF collection for Bucharest, Bangalore, Noida and France ECCs',
      },
      // {
      //   key: "list-item",
      //   value: "Engineering Efficiency Index (EEI) KPI",
      // },
      {
        key: 'list-item',
        value: 'WorkForce Breakdown (WFB) KPI',
      },
      // {
      //   key: "list-item",
      //   value: "Development On-Time Delivery (DOTD) KPI for LAS-Fr",
      // },

      // {
      //   key: "list-item",
      //   value: "Engineering Cockpit service gathering Competitiveness KPIs",
      // },
      {
        key: 'list-item',
        value: 'Improved role rights configuration',
      },
      {
        key: 'list-item',
        value: 'Improved onboarding of HoDs, EDMs and WPMs',
      },
      {
        key: 'list-item',
        value: 'Skills KPI & Drilling - correction of bugs and improvement',
      },
    ],
  },
  {
    version: '1.0.3 prel',
    date: '19/03/2024',
    notes: [
      {
        key: 'list-item',
        value: 'Team Manager onboarding',
      },
      {
        key: 'list-item',
        value: 'Update of the Role Level Security (RLS) in compliance with HR visibility rights',
      },
      {
        key: 'list-item',
        value: 'Non-Engineering Organization exculsion',
      },
      {
        key: 'list-item',
        value: 'Improved allocation of Skills data for Skills analysis',
      },
      {
        key: 'list-item',
        value: 'EMW ETL adjustments to cope with data source quality issues',
      },
      {
        key: 'list-item',
        value: 'Direct data collection from files & forms, yest available only to Super Users',
      },
    ],
  },
  {
    version: '1.0.2 prel',
    date: '19/12/2023',
    notes: [
      {
        key: '',
        value: 'Skills focus update (2nd part):',
      },
      {
        key: 'list-item',
        value: 'Update of the Skills Assessed Workers KPI',
      },
      {
        key: 'list-item',
        value: 'New "Evolution", "Distance & Avg" & "Distribution" Drill to to detail screens',
      },
      {
        key: 'list-item',
        value: '"Talents" screen renamed "Workers" + performance improvement',
      },
      {
        key: '',
        value: 'Mood & Confidence to Deliver Drill through update with Participation rate',
      },
      {
        key: '',
        value: 'Super User dedicated services, including:',
      },
      {
        key: 'list-item',
        value: 'CC list synchronisation with external tools',
      },
      {
        key: 'list-item',
        value: 'Engineering Organization analysis (1st part)',
      },
      {
        key: '',
        value: 'Administration services',
      },
    ],
  },
  {
    version: '1.0.1 prel',
    date: '7/12/2023',
    notes: [
      {
        key: '',
        value: 'Skills focus update (1st part):',
      },
      {
        key: 'list-item',
        value: 'New Skills Assessed Workers KPI',
      },
      {
        key: 'list-item',
        value: `New "Talents" Skills Drill to detail screen`,
      },
    ],
  },
  {
    version: '1.0.0 prel',
    date: '21/09/2023',
    notes: [
      {
        key: '',
        value: 'Preliminary V1 version that includes:',
      },
      {
        key: 'list-item',
        value: 'New landing page and UX',
      },
      {
        key: 'list-item',
        value:
          'Engineering organization visualisation, browsing and management (maintenance, collaboration, export)',
      },
      {
        key: 'list-item',
        value:
          'Mood & Confidence to deliver Drill to detail with project names provided on premise',
      },
      {
        key: 'list-item',
        value: 'Skills focus improvements',
      },
    ],
  },
  {
    version: '0.4.1',
    date: '18/07/2023',
    notes: [
      {
        key: 'list-item',
        value: 'Technical version preparing EMW V1',
      },
    ],
  },
  {
    version: '0.4.0',
    date: '04/04/2023',
    notes: [
      {
        key: 'list-item',
        value: 'Partial release of Skills dashboard',
      },
    ],
  },
  {
    version: '0.3.5',
    date: '22/02/2023',
    notes: [
      {
        key: 'list-item',
        value: 'Fix access rights regression for Engineering Managers at GBU level',
      },
      {
        key: 'list-item',
        value: 'Fix regression when filtering by selecting histogram bars on M&CtD drill to detail',
      },
      {
        key: 'list-item',
        value:
          'Provide access rights to German Managers whose data are provided through Workday exports',
      },
    ],
  },
  {
    version: '0.3.4',
    date: '14/02/2023',
    notes: [
      {
        key: 'list-item',
        value:
          'Engineering Organization service disabled prior to future extended service availability',
      },
      {
        key: 'list-item',
        value: 'Access to EMW with both non federated and contractual email addresses',
      },
    ],
  },
  {
    version: '0.3.3',
    date: '24/11/2022',
    notes: [
      {
        key: 'list-item',
        value: 'Rolling Window (6 months by default)',
      },
      {
        key: 'list-item',
        value: 'Fix on Mood & Confidence to deliver participation color',
      },
    ],
  },
  {
    version: '0.3.2',
    date: '19/07/2022',
    notes: [
      {
        key: 'list-item',
        value: 'Mood & Confidence to deliver drill to detail histogram improved when few answers',
      },
      {
        key: 'list-item',
        value: "'Contact us' button added on the Landing Page",
      },
    ],
  },
  {
    version: '0.3.1',
    date: '17/06/2022',
    notes: [
      {
        key: 'list-item',
        value: 'Fix of the access scheme for users having multiple roles',
      },
    ],
  },
  {
    version: '0.3.0',
    date: '13/05/2022',
    notes: [
      {
        key: 'list-item',
        value: 'New section for displaying the Engineering organization',
      },
      {
        key: 'list-item',
        value: "Mood & Confidence to deliver KPI's visualized with related participation",
      },
      {
        key: 'list-item',
        value: 'Mood & Confidence to deliver word cloud on verbatims added in Drill to detail page',
      },
      {
        key: 'list-item',
        value: 'Harmonized navigation throuh time using date & period setting',
      },
      {
        key: 'list-item',
        value: 'Simplified navigation through the Engineering organization using filters',
      },
      {
        key: 'list-item',
        value: 'Improved landing page',
      },
    ],
  },
  {
    version: '0.2.2',
    date: '07/02/2022',
    notes: [
      {
        key: 'list-item',
        value: "Mood & Confidence to deliver KPI's rolling average adjustment for 2 month periods",
      },
    ],
  },
  {
    version: '0.2.1',
    date: '21/01/2022',
    notes: [
      {
        key: 'list-item',
        value: 'Access scheme visibility rules update',
      },
      {
        key: 'list-item',
        value: 'Drill through sorting & Engineering perimeter highlight',
      },
      {
        key: 'list-item',
        value: '2 month period for Mood & Confidence to Deliver',
      },
    ],
  },
  {
    version: '0.2.0',
    date: '10/12/2021',
    notes: [
      {
        key: 'list-item',
        value: 'Mood & Confidence to deliver specific focus with drilling',
      },
      {
        key: 'list-item',
        value: 'Access scheme',
      },
      {
        key: 'list-item',
        value: 'Filtering',
      },
    ],
  },
  {
    version: '0.1.0',
    date: '30/09/2021',
    notes: [
      {
        key: 'list-item',
        value: 'DOTD/DAVD, Mood, Confidence to deliver KPI’s for AVS (early adopter) @ GBU level',
      },
    ],
  },
];
