const subscribe = (eventName, listener) => {
  document.addEventListener(eventName, listener);
};

const unsubscribe = (eventName, listener) => {
  document.removeEventListener(eventName, listener);
};

const publish = (eventName, data) => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};

const EVENTS = {
  COMPETITIVENESS: 'COMPETITIVENESS',
  OPERATING_MODEL: 'OPERATING MODEL',
  CRAFTSMANSHIP_MODEL: 'CRAFTSMANSHIP MODEL',
  TALENTS_AND_CULTURE: 'TALENTS & CULTURE',
  TARGETS_DOTD: 'TARGETS_DOTD',
  TARGETS_DADV: 'TARGETS_DADV',
  TARGET_CONFIDENCE_TO_DELIVER: 'TARGET_CONFIDENCE_TO_DELIVER',

  // Job and family event
  BROWSING: 'JOB FAMILY BROWSING',
  ANALYSIS: 'JOB FAMILY ANALYSIS',
  TRANSFORMATION: 'MIGRATION TRANSFORMATION',
};

export { EVENTS, publish, subscribe, unsubscribe };
