import React, { useContext, useState, useEffect } from 'react';
import '../styled';
import { StyledExportOrganizationHeader } from '../styled';
import { Box } from '@mui/material';
import { TdsCheckbox } from '@tds/react';
import { ExportOrganizationContext } from '../context/ExportOrganizationContext';
import { HeadCount } from '../../../../../constants';

const checkBoxList = [
  {
    name: 'Headcount Calculated',
    label: `${HeadCount.E_HC.label}`,
    value: 'hcCalculatedRequested',
  },
  {
    name: 'Headcount JobFamily',
    label: `${HeadCount.EJF_HC.label}`,
    value: 'hcJobFamilyRequested',
  },
  {
    name: 'Headcount Declared',
    label: `${HeadCount.DEC_HC.label}`,
    value: 'hcDeclaredRequested',
  },
  {
    name: 'Countries',
    label: 'Countries',
    value: 'countriesRequested',
  },
  {
    name: 'Organization Type',
    label: 'Org. Type',
    value: 'organisationTypeRequested',
  },
  {
    name: 'Engineering Domain',
    label: 'Eng. Domain',
    value: 'engDomainRequested',
  },
  {
    name: 'Guest/Power Guest',
    label: 'Guest/Power Guest',
    value: 'guestPowerGuestRequested',
  },
  {
    name: 'Team Managers',
    label: 'Team Managers',
    value: 'isTeamManagerRequested',
  },
  {
    name: 'Underlying Hierarchy',
    label: 'Underlying Hierarchy',
    value: 'underlyingHierarchyRequested',
  },
];
const allChecks = checkBoxList.map(item => item.value);

export default function HeaderCheckList({ data }) {
  const context = useContext(ExportOrganizationContext);
  const [selectAllChecks, setSelectAllChecks] = useState(false);
  const [selectedChecks, setSelectedChecks] = useState([]);

  useEffect(() => {
    if (selectedChecks.length === 0) {
      setSelectAllChecks(false);
    }
    if (selectedChecks.length === checkBoxList.length) {
      setSelectAllChecks(true);
    }
    if (selectedChecks.length > 0 && selectedChecks.length < checkBoxList.length) {
      setSelectAllChecks(false);
    }
  }, [selectedChecks]);

  const handleClick = (event, value) => {
    const { checked } = event.target;
    context.setEngRequested(value, checked);
    const isIncluded = selectedChecks.includes(value);

    if (checked && selectedChecks) {
      setSelectedChecks([...selectedChecks, value]);
    }
    if (!checked || isIncluded) {
      const updatedChecks = selectedChecks.filter(item => item !== value);
      setSelectedChecks(updatedChecks);
    }
  };

  const handleSelectAll = event => {
    const { checked } = event.target;
    setSelectAllChecks(true);
    checked ? setSelectedChecks(allChecks) : setSelectedChecks([]);

    checkBoxList.forEach(checkBox => {
      context.setEngRequested(checkBox.value, checked);
    });
  };

  const renderCheckList = () => {
    return checkBoxList.map((item, key) => {
      return (
        <Box>
          <TdsCheckbox
            size="small"
            name={item.name}
            title={item.name}
            item={item}
            onClick={e => handleClick(e, item.value)}
            label={item.name}
            value={item.value}
            checked={context.state[item.value]}
          >
            {item.label}
          </TdsCheckbox>
        </Box>
      );
    });
  };

  return (
    <StyledExportOrganizationHeader
      item
      id="export-dialog-checkbox"
      className="flex items-center mt-s mb-s"
    >
      <div className="flex flex-grow justify-between">
        {renderCheckList()}
        <TdsCheckbox
          size="small"
          // indeterminate={indeterminate}
          checked={selectAllChecks}
          name="Select All"
          onClick={handleSelectAll}
          label="Select All"
        >
          Select All
        </TdsCheckbox>
      </div>
    </StyledExportOrganizationHeader>
  );
}
